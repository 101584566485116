import { mapHelper } from "@/utils/common.js";
//积分类型
const pointType = [
  { value: 0, label: "积分扫码扣除" },
  { value: 1, label: "活动签到" },
  { value: 2, label: "扫码获取" },
  { value: 3, label: "未来心愿" },
  { value: 4, label: "社区共建" },
  { value: 5, label: "公益捐赠" },
  { value: 6, label: "活动发布" },
  { value: 7, label: "运动打卡" },
  { value: 8, label: "社区达人申请" },
  { value: 9, label: "积分商城兑换" },
  { value: 11, label: "用户注册" },
  { value: 12, label: "每日签到" },
  { value: 13, label: "点赞任务" },
  { value: 14, label: "评论任务" },
  { value: 15, label: "完善资料" },
  { value: 16, label: "与微信互动" },
  { value: 17, label: "超话活动" },
  { value: 18, label: "党建在线学习" },
  { value: 19, label: "邻里公约" },
  { value: 20, label: "访问积分商城" },
  { value: 21, label: "访问超话" },
  { value: 22, label: "访问广播站" },
  { value: 23, label: "访问活动广场" },
  { value: 24, label: "组合任务额外加分" },
  { value: 25, label: "社群场地预约" },
  { value: 26, label: "商圈扫码" },
  { value: 27, label: "时间银行签到" },
  { value: 99, label: "积分转赠" },
];

const { map: pointTypeMap, setOps: setPointTypeOps } =
  mapHelper.setMap(pointType);

export { pointTypeMap, setPointTypeOps };
